import { useEffect } from 'react'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Loading from 'src/components/Library/Loading/Loading'
import { useAuth } from 'src/Providers'

const LogoutPage = () => {
  const { logOut } = useAuth()

  useEffect(() => {
    logOut().then(() => {
      navigate(routes.login())
    })
  }, [logOut])

  return (
    <>
      <Metadata title="Logout" description="Logout page" />
      <div className="w-full min-h-[100vh] grid place-items-center">
        <div className="flex flex-col gap-4">
          <h1>Logging you out</h1>
          <Loading />
        </div>
      </div>
    </>
  )
}

export default LogoutPage
